/**  在使用时发现类型不对的地方记得及时的修改类型 */
// type WidthType = {
//   widthType: 'width'
//   width: string
//   minWidth: string
//   maxWidth?: string
// }

// type InteractionType = 'input' | 'select'

type SaveDataColumn = {
  temp: string
  require?: boolean | undefined
  prop: string
  headSlotName: string
  width: string
  minWidth: string
  maxWidth: string
  label?: string | undefined
  interactionType?: string | undefined
  widthType?: string | undefined
  tooltip?: boolean | undefined
  verify?: boolean | undefined
  selectProp?: string | undefined
}

type PlankOrder = {
  plankOrder: number
  plankType: string
  matCode: string
  plankSize: string
  plankEdgeOff: number
  texture: string
  thick: string | number
  isHighPlank: string
  amount: number
  id: string
}

type PlankOrderColumn = {
  label: string
  prop: string
  width: string
  align?: string
}

type StandardPlank = {
  title: string
  value: string
}

type MaterialRes = {
  defaultPlankInfo: StandardPlankInfo[]
  specialPlankInfo: SpecialPlankInfo[]
  isAutoSelected: boolean
  auto_add_special_plate: boolean
  last_sync_plank_time: string
  plate_knife_map: any[]
}

type StandardPlankInfo = {
  plankHeight: number
  plankWidth: number
  plankEdgeOff: number
  standard_plank_id: string | number
  default_selected: boolean
}

type SpecialPlankInfo = {
  id: number
  color: string
  thick: number
  width: number
  height: number
  window_plank_id: string
  trim_edge: number
  matCode: string
  isPicked: boolean
  symbol: string
}

export type PreferencesSetting = {
  genSmallPlankLabel: boolean
  surplusAutoTailor: boolean
  surplusTailorWay: string
  autoSaveLayout: boolean
  layoutMethod: string
  labelTemplate: number
  surplusLabel: boolean
  bigPlankLabel: boolean
  labelCoord: {
    X: number
    Y: number
  }
  mirrorLabel: boolean
  boardBenchmark: boolean
  labelSortWay: number | string
  labelColumnNum: number
  labelColumnGap: number
  pick_up_data: boolean
  is_part_rec: boolean
  is_slab_rec: boolean
  is_remnant_rec: boolean
  is_clear_slab_plank: boolean
  process_line_id: number
  paibanWay: string
  isLessreverse: boolean
  isSpecialShape: boolean
  isLessReverse: boolean
  isCuttingOrder: boolean
  isAutoPassData: boolean
  longSideRect: number
  shortSideRect: number
  opacity: number
  cutNeedLave: boolean
  isFollowExportTag: boolean
  currentTagTempID: number
  currentTagType: string
  isShowPartSize: boolean
  process_line_name: string
  needSaveData: SaveDataColumn[]
  isLoadMoreTable: boolean
  isGenNcSuffix: boolean
  isCheckBigPlankSynchronous: boolean
  autoSave: string
  highlightFlipType: string
  surplus_no_roll: boolean
  isSurplusNoRoll: boolean
  size: number
  config: boolean
  sizeAuto: boolean
  isAutoCutting: boolean
  cuttingSize: number
  standardPlankList: StandardPlank[]
  selectStandardPlank: string
  customPlankOrder: boolean
  plankOrderList: PlankOrder[]
  plankOrderColumns: PlankOrderColumn[]
  isEnoughOrOversize: boolean
  materialRes: MaterialRes
  isBujianRecommend: boolean
  isPaibanStoreRecommend: boolean
  isSurplusRecommend: boolean
  saved_hightligh_prop: boolean
  isDeductionSurplus: boolean
  isDeductionBujian: boolean
  isDeductionPaibanStore: boolean
  engravSwitchCloseAlwaysShow?: boolean
  checkKnifeCode?: boolean
  is_use_folder_export: boolean
  isDeductionBaseMaterial?: boolean
  surplus_lock: boolean
  exPaibanTipShow: boolean
  saved_highlight_prop: boolean
  isUseSeniorNest: boolean
  col_space: number
  isCombineToLShape: boolean
  nesting_version: 1 | 2
}

export interface IStore {
  setting: Partial<PreferencesSetting>
  isLoading: boolean
  isLoadDone: boolean
}
